<template>
  <div>
    <no-data-component :label="$t('common.no_history')" v-if="eventLogs.length === 0"/>
    <time-line type="simple" class="wizard-timeline" v-if="eventLogs.length">
      <time-line-item
        inverted
        v-for="(event, index) in eventsList"
        v-bind:key="`event-${index}`"
        :badge-type="dealHistoryComponentConst[event.loggable_type].color"
        :badge-icon="dealHistoryComponentConst[event.loggable_type].icon"
      >
        <div slot="header" class="d-flex justify-content-between align-items-center">
          <div :class="`badge badge-${dealHistoryComponentConst[event.loggable_type].color}`">
            {{ $t(dealHistoryComponentConst[event.loggable_type].titleLang) }}
          </div>
          <div class="text-truncate">
            {{ event.date | date }} <span class="small text-muted">{{ event.time | time }}</span>
          </div>
        </div>
        <div slot="content">
          <div v-if="event.user_id && permissionService.isAllowed([
              permissionService.SALES_MANAGER_ROLE,
              permissionService.CALL_CENTER_OPERATOR_ROLE
            ])"
          >
            <small>
              {{ $t('common.powered_by') }}
              {{ users[event.user_id] | optional('firstname') }}
              {{ users[event.user_id] | optional('lastname') }}
            </small>
          </div>

          <component
            :is="dealHistoryComponentConst[event.loggable_type].component"
            :key="event.id"
            :id = "event.id"
            :dealEventLog="event"
          />
        </div>
      </time-line-item>
    </time-line>

    <div class="row" v-if="eventsPages.length > 1">
      <div class="col-12 d-flex justify-content-end">
        <card class="card card-plain mb-1" body-classes="standard-card-body">
          <base-pagination class="mb-0"
                           v-model="currentPage"
                           :per-page=numberItemsPerPage
                           :total="eventLogs.length"/>
        </card>
      </div>
    </div>

  </div>
</template>

<script>

import {TimeLine, TimeLineItem} from 'src/components';
import OctoIcon from "@/components/octo-icon/OctoIcon";
import BaseAlert from "@/components/BaseAlert";
import {mapGetters} from "vuex";
import BasePagination from "@/components/BasePagination";
import {endpoints} from "@/routes/endpoints";
import Deal from "@/models/deal";
import NoDataComponent from "@/components/NoDataComponent";
import {permissionService} from "@/util/permission-service";
import DealHistoryComponentConst from "@/pages/Deals/resources/dealHistoryComponentConst";

export default {
  name: "DealHistory",
  components: {
    NoDataComponent,
    BasePagination,
    BaseAlert,
    OctoIcon,
    TimeLine,
    TimeLineItem
  },
  data() {
    return {
      currentPage: 1,
      eventLogs: [],
      permissionService: permissionService,
      dealHistoryComponentConst: DealHistoryComponentConst
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users'
    }),
    eventsPages() {
      return this.$_.chunk(this.eventLogs, this.numberItemsPerPage);
    },
    eventsList() {
      return this.eventsPages[this.currentPage - 1];
    }
  },
  props: {
    deal: {
      type: Object,
      default: () => this.$_.cloneDeep(Deal)
    },
    numberItemsPerPage: {
      type: Number,
      default: 5
    }
  },
  mounted() {
    if (this.deal.id) {
      this.$api.get(endpoints.DEAL_GET_EVENT_LOGS.replace('{id}', this.deal.id))
        .then((resp) => {
          this.eventLogs = resp?.data;
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.loading_error')})
        });
    }
  },
}
</script>

<style scoped lang="scss">
</style>
