<template>
  <card class="shadow" body-classes="standard-card-body">
    <ul class="list-group list-group-flush">
      <list-group-title-section-component
        :label="$t('callcenter.deal_data')"
      />
      <list-group-item-component
        :value="deal.date_in | date"
        :label="$t('fields.date_start')"
      />
      <list-group-item-component
        :value="deal.date_out | date"
        :label="$t('fields.date_end')"
      />
      <list-group-item-component
        v-if="deal.lead && deal.lead.user_id && permissionService.isAllowed([
              permissionService.SALES_MANAGER_ROLE,
              permissionService.CALL_CENTER_OPERATOR_ROLE
        ])"
        :label="$t('fields.operator')"
      >
        <template slot="value">
          <div class="d-flex align-items-center">
            <label-theme-component>
              {{ users[deal.lead.user_id] | optional('lastname') }}
              {{ users[deal.lead.user_id] | optional('firstname') }}
            </label-theme-component>
          </div>
        </template>
      </list-group-item-component>
      <list-group-item-component v-if="deal && deal.user_id"
                                 :label="$t('fields.sale')"
      >
        <template slot="value">
          <div class="d-flex align-items-center">
            <label-theme-component>
              {{ users[deal.user_id] | optional('lastname') }}
              {{ users[deal.user_id] | optional('firstname') }}
            </label-theme-component>
          </div>
        </template>
      </list-group-item-component>
    </ul>
  </card>
</template>

<script>
import Deal from "@/models/deal";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import {mapGetters} from "vuex";
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";
import {permissionService} from "@/util/permission-service";

export default {
  name: "DealDataContainer",
  components: {ListGroupTitleSectionComponent, LabelThemeComponent, ListGroupItemComponent},
  data() {
    return {
      permissionService: permissionService
    }
  },
  props: {
    deal: {
      type: Object,
      default: () => this.$_.cloneDeep(Deal)
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users',
      operators: 'common/operators',
      sales: 'common/sales',
    }),
  },
}
</script>

<style scoped>

</style>
