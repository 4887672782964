<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <card class="card shadow" body-classes="standard-card-body">
          <operator-action-container
            direction="column"
            :phones="registry.phones"
            :emails="registry.emails"
            :lead="deal.lead"
            :appointment-id="appointmentId"
            :course-ids="courseIds"
            :name="registry | optional('name')"
            :surname="registry | optional('surname')"
            @onStartCall="leadHistoryKey++"
            @onEmailSent="leadHistoryKey++"
          />
        </card>
      </div>
      <div class="col-md-12">
        <tabs centered square>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('deal.deal') }}</div>
              </div>
            </template>
            <div class="row mb-2">
              <div class="col-12">
                <octo-header-button
                  :buttons="headerButtons"
                  :title="registry.surname + ' ' + registry.name"
                  @onSaveDeal="saveDeal"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-7">
                <deal-summary
                  :deal="deal"
                  show-deal-dates
                  ref="dealSummary"
                  :allowed-status="availableStatuses"
                  :key="`summary-${dealDataKey}`"
                />
              </div>
              <div class="col-md-5">
                <deal-status-container :deal="deal" :key="`dealStatusContainer-${dealDataKey}`"/>
                <deal-history :deal="deal" :key="`dealHistory-${dealDataKey}`"/>
              </div>
            </div>
          </tab-pane>

          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('callcenter.organizer') }}</div>
              </div>
            </template>
            <octo-header-button :title="registry.surname + ' ' + registry.name"/>
            <deal-date-to-confirm-scheduler
              :enable-statuses="false"
              :operator="currentUser"
              :deal="deal"
              :deal-dates="deal.dealDates || []"
              :date-min="dateMin"
              :sales="sales"
              :registry="registry"
              @onDeleteDealDate="deleteDealDate"
              @onUpdateDealDate="updateDealDate"
              @onHandleCalendarUpdated="(event) => this.appointmentId = event.id"
              ref="dealDateToConfirmScheduler"
            />

          </tab-pane>

          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('callcenter.lead') }}</div>
              </div>
            </template>
            <octo-header-button
              :buttons="leadButtons"
              :title="registry.surname + ' ' + registry.name"
              @onOpenLead="$router.push({name: 'leads.archive.show', params: {id: deal.lead.id}})"
            />
            <lead-tab-pane v-if="deal.lead" :lead="deal.lead" :key="`lead-tab-pane-${leadHistoryKey}`"/>
          </tab-pane>

          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('common.registry') }}</div>
              </div>
            </template>
            <octo-header-button
              :buttons="registryButtons"
              :title="registry.surname + ' ' + registry.name"
              @onOpenRegistry="$router.push({name: 'registries.show', params: {id: registry.id}})"
            />
            <registry-tab-pane :registry="registry" :key="`registry-tab-pane-${leadHistoryKey}`"/>
          </tab-pane>
        </tabs>
      </div>
      <modal
        bodyClasses="px-2"
        modalClasses="modal-lg"
        centered
        :showClose="false"
        :show.sync="showModal"
      >
        <h5 slot="header" class="modal-title text-uppercase">
          {{ $t('callcenter.select_appointment_to_work_with') }}
        </h5>
        <list-selector-component exclusive :list="deal.dealDates" ref="listSelectorComponent">
          <template v-slot:default="slotProps">
            <div class="mb-2">
              {{ users[slotProps.item.user_id] | optional('lastname') }}
              {{ users[slotProps.item.user_id] | optional('firstname') }}
            </div>
            <div class="mb-2">
              {{ slotProps | optional('item.date_start') | date }}
            </div>
            <div class="small">
              {{ slotProps | optional('item.date_start') | time }} -
              {{ slotProps | optional('item.date_end') | time }}
            </div>
          </template>
        </list-selector-component>

        <template slot="footer">
          <base-button link @click="selectDealDateFromModal">
            <span class="text-uppercase">{{ $t('common.select') }}</span>
          </base-button>
        </template>
      </modal>
    </div>
  </div>


</template>

<script>

import {endpoints} from "@/routes/endpoints";
import Deal from "@/models/deal";
import Registry from "@/models/registry";
import DealDate from "@/models/dealDate";
import {TabPane, Tabs} from "@/components";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import DealStatuses from "@/pages/Deals/resources/dealStatuses";
import DealStatusContainer from "@/pages/Deals/components/DealStatusContainer";
import DealSummaryGeneral from "@/pages/Deals/components/DealSummaryGeneral";
import DealSummary from "@/pages/Deals/components/DealSummary";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import {mapGetters} from "vuex";
import RegistryPersonalData from "@/pages/Registries/components/RegistryPersonalData";
import OctoEntityAddresses from "@/components/octo-entity-addresses/OctoEntityAddresses";
import OctoEntityEmails from "@/components/octo-entity-emails/OctoEntityEmails";
import OctoEntityPhones from "@/components/octo-entity-phones/OctoEntityPhones";
import DealDataContainer from "@/pages/Deals/components/DealDataContainer";
import OperatorActionContainer from "@/pages/Operator/component/OperatorActionContainer";
import LeadHistoryCall from "@/pages/CallCenter/OperatorComponents/LeadHistoryCall";
import LeadHistory from "@/pages/CallCenter/OperatorComponents/LeadHistory";
import RegistryHistory from "@/pages/Registries/components/RegistryHistory";
import Modal from "@/components/Modal";
import ListSelectorComponent from "@/components/ListSelectorComponent";
import DealDateToConfirmScheduler from "@/components/DealDateScheduler/DealDateToConfirmScheduler";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import DealHistory from "@/pages/Deals/components/DealHistory";
import RegistryTabPane from "@/components/TabPanels/RegistryTabPane";
import LeadTabPane from "@/components/TabPanels/LeadTabPane";
import {permissionService} from "@/util/permission-service";

export default {
  name: "ShowDealToConfirmPage",
  components: {
    LeadTabPane,
    RegistryTabPane,
    DealHistory,
    OctoHeaderButton,
    DealDateToConfirmScheduler,
    ListSelectorComponent,
    Modal,
    RegistryHistory,
    LeadHistoryCall,
    OperatorActionContainer,
    DealDataContainer,
    OctoEntityPhones,
    OctoEntityEmails,
    OctoEntityAddresses,
    RegistryPersonalData,
    LabelThemeComponent,
    ListGroupItemComponent,
    DealSummary,
    DealSummaryGeneral,
    DealStatusContainer,
    OctoIcon,
    TabPane,
    Tabs,
    LeadHistory,
  },
  data() {
    return {
      dealDataKey: 1,
      leadHistoryKey: 1,
      operatorActionContainerKey: 1,
      deal: this.$_.cloneDeep(Deal),
      registry: this.$_.cloneDeep(Registry),
      endpoints: endpoints,
      dealStatuses: DealStatuses,
      showModal: false,
      headerButtons: [{
        label: 'save',
        onClick: 'onSaveDeal'
      }],
      permissionService: permissionService,
      appointmentId: null
    }
  },
  async beforeMount() {
    try {
      this.$fullLoading.show();
      const resp = await this.$api.get(endpoints.DEAL_SHOW.replace('{id}', this.$route.params.id))
      this.deal = resp?.data?.data || this.$_.cloneDeep(Deal);
      this.registry = this.deal?.registry || this.$_.cloneDeep(Registry);

      this.dealDataKey++;
      this.leadHistoryKey++;
      this.$fullLoading.hide();

      if (this.deal?.dealDates?.length > 1) {
        this.showModal = true;
      } else {
        const dealDateToSet = this.$_.head(this.$_.orderBy(this.deal.dealDates, ['dateStart', 'desc']))
          || this.$_.cloneDeep(DealDate);

        this.setDealDate(dealDateToSet);
      }
    } catch (e) {
      console.log(e);
      this.$fullLoading.hide();
      this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
      await this.$router.push({name: "deals.to_confirm"});
    }

  },

  computed: {
    ...mapGetters({
      users: 'common/users',
      operators: 'common/operators',
      sales: 'common/sales',
      currentUser: 'auth/currentUser'
    }),

    dateMin() {
      return this.$moment().toDate();
    },

    availableStatuses() {
      switch (true) {
        case this.permissionService.isAllowed([permissionService.CALL_CENTER_OPERATOR_ROLE]):
          return [
            this.dealStatuses.confirmed.value,
            this.dealStatuses.not_confirmed.value,
          ];
        default:
          return [
            this.dealStatuses.confirmed.value,
            this.dealStatuses.not_valid.value,
            this.dealStatuses.not_interested.value,
          ];
      }
    },

    courseIds() {
      return this.$_.map(this.deal.deal_details, dealDetail => dealDetail.detailable_id)
    },

    registryButtons() {
      const buttons = [];

      if (permissionService.isAllowed([permissionService.CALL_CENTER_MANAGER_ROLE])) {
        buttons.push({label: 'open', onClick: 'onOpenRegistry'});
      }

      return buttons;
    },

    leadButtons() {
      const buttons = [];

      if (
        permissionService.isAllowed([permissionService.CALL_CENTER_MANAGER_ROLE])
        || (
          permissionService.isAllowed([permissionService.CALL_CENTER_OPERATOR_ROLE])
          && this.deal.lead.user_id === this.currentUser.id
        )
      ) {
        buttons.push({label: 'open', onClick: 'onOpenLead'})
      }

      return buttons;
    }
  },
  methods: {
    saveDeal: async function () {
      try {

        const newStatus = this.$refs.dealSummary.getStatus();
        const newSubStatus = await this.$refs.dealSummary.getSubStatus();

        if (newStatus === DealStatuses.confirmed.value) {
          const appointment = this.$refs.dealDateToConfirmScheduler.getCurrentEvent();
          if (!appointment.id) {
            this.$notify({
              type: 'warning',
              message: this.$t('notifications.cannot_update_deal_to_recall_if_status_not_confirmed')
            });
            return;
          }
        }

        this.$fullLoading.show();

        const resp = await this.$api.put(
          endpoints.DEAL_TO_CONFIRM_UPDATE.replace('{id}', this.deal.id),
          {
            status: newStatus ? newStatus : this.deal.status,
            sub_status: newStatus ? newSubStatus : this.deal.sub_status,
            text: this.deal.text,
          }
        );
        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});

        if (resp?.data?.data?.status !== this.dealStatuses.pending.value) {
          await this.$router.push({name: 'deals.to_confirm'});
        }

      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },
    selectDealDateFromModal() {
      const selectedItemId = this.$refs.listSelectorComponent.getSelectedItem();

      if (selectedItemId) {
        const dealDateSelected = this.$_.remove(this.deal.dealDates, dealDate => {
          return dealDate.id === selectedItemId;
        })[0];

        this.setDealDate(dealDateSelected);

        this.showModal = false;
      } else {
        this.$notify({type: 'danger', message: this.$t('notifications.deal_date_not_selected')});
      }
    },
    setDealDate(dealDate) {
      this.appointmentId = dealDate.id;
      this.deleteDealDate(dealDate);
      this.$refs.dealDateToConfirmScheduler.setDealDateLocal(dealDate);
    },
    deleteDealDate(item) {
      this.$set(this.deal, 'dealDates', this.$_.filter(this.deal.dealDates, (dealDate) => {
        return dealDate.id !== item.id
      }))
    },
    updateDealDate(item) {
      this.$set(
        this.deal.dealDates,
        this.$_.findIndex(this.deal.dealDates, (dealDate) => dealDate.id === item.id),
        item
      );
    },
  }
}
</script>

<style scoped lang="scss">

</style>
