const EmailMessage = {
  'module': '',
  'template': '',
  'subject': '',
  'message': '',
  'cc': [],
  'attachments': {}
};

export default EmailMessage
