<template>
  <div>
    <card class="shadow" body-classes="standard-card-body">
      <ul class="list-group list-group-flush">
        <list-group-item-component
          :value="deal.date_in | date"
          :label="$t('fields.date_start')"
        />
        <list-group-item-component
          :value="deal.date_out | date"
          :label="$t('fields.date_end')"
        />
        <list-group-item-component
          v-if="deal.lead && deal.lead.user_id && permissionService.isAllowed([
              permissionService.SALES_MANAGER_ROLE,
              permissionService.CALL_CENTER_OPERATOR_ROLE
          ])"
          :label="$t('fields.operator')"
        >
          <template slot="value">
            <div class="d-flex align-items-center">
              <label-theme-component>
                {{users[deal.lead.user_id] | optional('lastname') }}
                {{users[deal.lead.user_id] | optional('firstname') }}
              </label-theme-component>
              <base-button
                v-if="enableChangeOperator"
                icon
                link
                size="sm"
                @click="openOperatorsSelector"
              >
                <octo-icon icon="edit"/>
              </base-button>
            </div>
          </template>

        </list-group-item-component>
        <list-group-item-component
          :label="$t('fields.sale')"
        >
          <template slot="value">
            <div class="d-flex align-items-center">
              <label-theme-component>
                {{users[deal.user_id] | optional('lastname') }}
                {{users[deal.user_id] | optional('firstname') }}
              </label-theme-component>
              <base-button v-if="enableChangeSale"
                icon
                link
                size="sm"
                @click="openSalesSelector"
              >
                <octo-icon icon="edit"/>
              </base-button>
            </div>
          </template>
        </list-group-item-component>
      </ul>
    </card>
    <modal
      bodyClasses="p-1"
      :show.sync="showModal"
      @close="showModal=false"
      centered
      modalClasses="modal-lg">
      <template slot="header">
        <h5 class="modal-title text-uppercase">
          {{modalTitle}}
        </h5>
      </template>
      <list-selector-component
        exclusive
        :number-items-per-page="8"
        :list="modalUsers"
        ref="listSelectorComponent">
        <template v-slot:default="slotProps">
          <div>
            {{ users[slotProps.item.id] | optional('lastname') }}
            {{ users[slotProps.item.id] | optional('firstname') }}
          </div>
        </template>
      </list-selector-component>
      <template slot="footer">
        <base-button class="text-uppercase"
                     link
                     @click="updateUser"
                     :confirm-required="this.modalTarget === 'operator'"
                     :confirm-text="$t('confirm.change_expired_deal_operator_text')"
        >
          {{ $t('common.save') }}
        </base-button>
      </template>
    </modal>
  </div>

</template>

<script>
import Deal from "@/models/deal";
import {mapGetters} from "vuex";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Modal from "@/components/Modal";
import ListSelectorComponent from "@/components/ListSelectorComponent";
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";
import {permissionService} from "@/util/permission-service";

export default {
  name: "DealSummaryGeneral",
  components: {
    ListGroupTitleSectionComponent,
    ListSelectorComponent,
    OctoIcon,
    LabelThemeComponent,
    ListGroupItemComponent,
    Modal
  },
  data() {
    return {
      showModal: false,
      modalTitle: 'title',
      modalUsers: [],
      modalTarget : null,
      permissionService: permissionService
    }
  },
  props: {
    deal: {
      type: Object,
      default: () => this.$_.cloneDeep(Deal)
    },
    enableChangeOperator: {
      type: Boolean,
      default: false
    },
    enableChangeSale: {
      type: Boolean,
      default: false
    }
  },
  computed: {

    ...mapGetters({
      users: 'common/users',
      operators: 'common/operators',
      sales: 'common/sales',
    }),
  },
  methods: {
    openSalesSelector: function () {
      this.modalTitle = this.$t('common.change_sale');
      this.modalUsers = this.sales;
      this.modalTarget = 'sale';
      this.showModal = true;
    },
    openOperatorsSelector: function () {
      this.modalTitle = this.$t('common.change_operator');
      this.modalUsers = this.operators;
      this.modalTarget = 'operator';
      this.showModal = true;
    },
    updateUser: function () {
      if(this.modalTarget === 'sale') {
        this.$set(this.deal, 'user_id', this.$refs.listSelectorComponent.getSelectedItem())
      }
      if(this.modalTarget === 'operator') {
        this.$set(this.deal.lead, 'user_id', this.$refs.listSelectorComponent.getSelectedItem())
      }
      this.showModal = false;
    }
  }
}
</script>

<style scoped>

</style>
