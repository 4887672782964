<template>
  <modal :show="showModalLocal" @close="showModalLocal=false" centered modalClasses="modal-lg">
    <h5 slot="header" class="modal-title text-uppercase">
      {{ $t('common.send_to') }}: {{ receiver }}
    </h5>
    <div class="d-flex align-items-center mb-3">
      <div>{{ $t('common.cc') }}</div>
      <tags-input :placeholder="$t('fields.email')" v-model="localCc" tag-type="info"/>
    </div>
    <el-upload
      auto-upload
      drag
      class="attachment-upload"
      ref="upload-attachment"
      :action="endpoints.CALL_CENTER_STORE_TEMP_ATTACHMENT"
      :file-list="fileList"
      :on-remove="removeAttachments"
      :on-success="updateAttachments"
      :http-request="httpRequest"
      multiple>
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">Drop attachment here or <em>click to upload</em></div>
    </el-upload>
    <ValidationObserver ref="emailModalForm">
      <div class="row">
        <div class="col-8">
          <ValidationProvider
            name="email_subject"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
            <base-input
              required
              v-model="subject"
              type="text"
              :label="$t('fields.email_subject')"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
            >
            </base-input>
          </ValidationProvider>
        </div>
        <div class="col-4">
          <base-input :label="$t('common.template')" required>
            <el-select
              class="select-default text-uppercase"
              v-model="currentTemplate"
              collapse-tags
              @change="updateTemplate"
            >
              <el-option
                v-for="option in localTemplates"
                class="select-default text-uppercase"
                :value="option.id"
                :label="option.name"
                :key="option.id"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
      </div>

      <octo-rich-text
        class="mt-3"
        :key="richTextKey"
        :editor-height="400"
        :initial-value="message"
        @onEditorUpdate="(value) => this.message = value"/>

    </ValidationObserver>
    <template slot="footer">
      <base-button @click="sendEmail" :disabled="!!!this.currentTemplate" link class="text-uppercase">
        {{ $t('common.send') }}
      </base-button>
    </template>
  </modal>
</template>

<script>

import EmailMessage from "@/models/emailMessage";
import Modal from "@/components/Modal";
import {Select, Option, Upload} from 'element-ui';
import {endpoints} from "@/routes/endpoints";
import BaseTextArea from "@/components/Inputs/BaseTextArea";
import {Tag} from 'element-ui'
import TagsInput from "../Inputs/TagsInput";
import OctoRichText from "@/components/octo-rich-text/OctoRichText";

export default {
  name: "OctoEmailModal",
  data() {
    return {
      currentTemplate: null,
      showModalLocal: false,
      subject: '',
      message: '',
      endpoints: endpoints,
      localTemplates: [],
      localCc: [],
      messageToSend: this.$_.cloneDeep(EmailMessage),
      fileList: [],
      richTextKey: 1,
    }
  },
  components: {
    OctoRichText,
    TagsInput,
    BaseTextArea,
    Modal,
    [Option.name]: Option,
    [Select.name]: Select,
    [Upload.name]: Upload,
    [Tag.name]: Tag

  },
  props: {
    cc: {
      type: Array,
      default: () => []
    },
    templates: {
      type: Array,
      default: () => []
    },
    showModal: {
      type: Boolean,
      default: true
    },
    moduleName: {
      type: String,
      default: ''
    },
    emailFields: {
      type: Object,
      default: () => {
      }
    },
    selfManaged: {
      type: Boolean,
      default: false,
      description: 'se attivata gestisce tutto la finestra per quando rigarda il parse dei dati ma bisogna valorizzare emailFields'
    },
  },
  mounted() {
    this.showModalLocal = this.showModal;
    this.localTemplates = this.templates;
    this.localCc = this.cc;
    this.currentTemplate = (this.localTemplates.length > 0) ? this.localTemplates[0].id : null;
    if ((this.selfManaged) && (this.moduleName !== '')) {
      this.getEmailTemplates();
    }
    this.richTextKey++;
  },
  methods: {
    updateTemplate() {
      if (this.selfManaged) {
        this.parseTemplate(this.currentTemplate)
      }
      this.$emit('updateTemplate', this.currentTemplate);
    },

    updateAttachments(resp, file) {
      this.messageToSend.attachments[file.uid] = file.response.data;
    },

    removeAttachments(file) {
      delete this.messageToSend.attachments[file.uid];
    },

    sendEmail() {
      this.$refs.emailModalForm.validate()
        .then((success) => {
          if (success) {
            this.messageToSend.module = this.moduleName;
            this.messageToSend.template = this.currentTemplate;
            this.messageToSend.subject = this.subject
            this.messageToSend.message = this.message;
            this.messageToSend.receiver = this.emailFields.email;
            this.messageToSend.cc = this.localCc;
            this.$emit('sendEmail', this.messageToSend);
          } else {
            this.$notify({type: 'danger', message: this.$t('notifications.wizard_step_error')});
          }
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.wizard_step_error')});
        });

    },


    getEmailTemplates: function () {
      this.localTemplates = [];
      this.$api.get(endpoints.EMAIL_TEMPLATES_INDEX + '/' + this.moduleName).then(
        (resp) => {
          this.localTemplates = resp.data;
          this.currentTemplate = (this.localTemplates.length > 0) ? this.localTemplates[0].id : null;
        }
      ).catch((err) => {
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
      })
    },

    parseTemplate: function (templateName) {
      this.$fullLoading.show();
      this.$api.put(endpoints.EMAIL_TEMPLATE_PARSE, {
        module: this.moduleName,
        template: templateName,
        fields: this.emailFields
      }).then(
        (resp) => {
          this.message = resp.data.html;
          // this.setEmailMessage(atob(resp.data.html))
          this.richTextKey++;
        }
      ).catch((err) => {
        console.log(err)
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
      }).finally(() => {
        this.$fullLoading.hide();
      })

    },

    async httpRequest(option) {
      const formData = new FormData();
      formData.append(option.filename, option.file, option.file.name);

      try {
        return await this.$api.post(option.action, formData)
      } catch (e) {
        console.log(e);
      }
    },
  },
  computed: {
    receiver() {
      return `${this.emailFields.name || ""} ${this.emailFields.surname || ""} (${this.emailFields.email})`;
    },
  }
}
</script>

<style scoped lang="scss">


</style>
