<template>
  <div>
    <div class="d-flex justify-content-between align-items-center" v-if="isEventSalesChange">
      <div class="text-uppercase text-truncate">
        {{ users[entity.sales_from] | optional('firstname') }}
        {{ users[entity.sales_from] | optional('lastname') }}
      </div>
      <div>
        <octo-icon icon="right-arrow"/>
      </div>
      <div class="text-uppercase text-truncate">
        {{ users[entity.sales_to] | optional('firstname') }}
        {{ users[entity.sales_to] | optional('lastname') }}
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center" v-else>
      <div>
        <div class="text-uppercase text-truncate" v-if="entity.status_from">
          {{ $t('deal.status.' + entity.status_from) }}
        </div>
        <div v-if="entity.sub_status_from" class="small text-muted text-truncate">
          {{ $t('deal.status.' + entity.sub_status_from) }}
        </div>
      </div>
      <div>
        <octo-icon icon="right-arrow"/>
      </div>
      <div>
        <div class="text-uppercase text-truncate" v-if="entity.status_to">
          {{ $t('deal.status.' + entity.status_to) }}
        </div>
        <div v-if="entity.sub_status_to" class="small text-muted text-truncate">
          {{ $t('deal.status.' + entity.sub_status_to) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OctoIcon from "@/components/octo-icon/OctoIcon";
import DealStatusTransition from "@/models/dealStatusTransition";
import DealEventLog from "@/models/dealEventLog";
import {mapGetters} from "vuex";

export default {
  name: "DealHistoryStatusTransition",
  components: {OctoIcon},
  data() {
    return {
      entity: this.$_.cloneDeep(DealStatusTransition)
    }
  },
  props: {
    dealEventLog: {
      type: Object,
      default: () => this.$_.cloneDeep(DealEventLog)
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users'
    }),

    isEventSalesChange() {
      return this.entity.status_from === 'change_sales';
    },
  },
  mounted() {
    this.entity = this.dealEventLog?.dealEventLoggable
  },
}
</script>

<style scoped>

</style>
