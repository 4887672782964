import DealHistoryStatusTransition from "@/pages/Deals/components/DealHistoryStatusTransition";
import DealHistoryArchiveEmail from "@/pages/Deals/components/DealHistoryArchiveEmail";

const DealHistoryComponentConst = {
  DealStatusTransition: {
    component: DealHistoryStatusTransition,
    titleLang: 'deal.deal_status_transition',
    color: 'success',
    icon: 'sync',
  },
  EmailArchive: {
    component: DealHistoryArchiveEmail,
    titleLang: 'common.email_sent',
    color: 'info',
    icon: 'email',
  },
}

export default DealHistoryComponentConst
