<template>
  <div>
    <card class="shadow" body-classes="standard-card-body">
      <div class="row" v-if="!readOnly">
        <div class="col-md-6">
          <base-input :label="$t('callcenter.change_status')">
            <el-select
              class="select-default text-uppercase"
              :placeholder="$t('fields.choose')"
              v-model="newStatus"
              @change="newSubStatus = null"
            >
              <el-option
                v-for="option in dealStatuses"
                class="select-default text-uppercase w-100"
                :value="option.value"
                :label="option.label"
                :key="option.value"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-md-6">
          <base-input :label="$t('fields.substatus')">
            <el-select
              class="select-default text-uppercase"
              :placeholder="$t('fields.choose')"
              v-model="newSubStatus"
              :disabled="!dealSubStatuses.length"
            >
              <el-option
                v-for="option in dealSubStatuses"
                class="select-default text-uppercase w-100"
                :value="option.value"
                :label="option.label"
                :key="option.value"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-12 col-md-6 mb-md-2" v-if="showExtraFields">
          <base-input class="mb-0 flex-grow-1" :label="$t('fields.weekly_frequency')">
            <el-select
              class="select-default text-uppercase"
              :placeholder="$t('fields.choose')"
              v-model="deal.weekly_frequency"
            >
              <el-option
                v-for="(option, index) in weeklyFrequencyOptions"
                v-bind:key="`weekly-frequency-${index}`"
                class="select-default text-uppercase"
                :value="option"
                :label="option"
              />
            </el-select>
          </base-input>
        </div>
        <div class="col-12 col-md-6 mb-md-2" v-if="showExtraFields">
          <base-input class="mb-0 flex-grow-1" :label="$t('fields.individual_course')">
            <el-select
              class="select-default text-uppercase"
              :placeholder="$t('fields.choose')"
              v-model="deal.individual_course"
            >
              <el-option
                v-for="option in [
                  { value: true, label: $t('common.yes')},
                  { value: false, label: $t('common.no')},
                ]"
                class="select-default text-uppercase"
                :value="option.value"
                :label="option.label"
                :key="option.value"
              />
            </el-select>
          </base-input>
        </div>
        <div class="col-12 small text-muted mb-2" v-if="dealStatusInfo">
          {{ $t('deal.status.' + dealStatusInfo) }}
        </div>
      </div>


      <base-input v-model="deal.classroom_info" :label="$t('fields.classroom_info')"  class="flex-grow-1"/>


      <base-text-area v-model="deal.text" :label="$t('deal.deal_notes')"/>
    </card>

    <card class="shadow"
          body-classes="standard-card-body"
          v-if="showDealDates && deal.dealDates && deal.dealDates.length"
    >
      <div slot="header">
        <h4 class="card-title text-capitalize">{{ $t('datatable.appointments') }}</h4>
      </div>
      <ul
        class="list-group list-group-flush">
        <list-group-item-component
          v-for="(date, index) in deal.dealDates" v-bind:key="`dealDates-${index}`"
          :value="`${$options.filters.time(date.date_start)} - ${$options.filters.time(date.date_end)}`"
          :label="date.date_start | date"
        />
      </ul>
    </card>
  </div>
</template>

<script>
import Deal from "@/models/deal";
import DealStatuses from "@/pages/Deals/resources/dealStatuses";
import {Select, Option, Switch} from "element-ui";
import DealExtraInfo from "@/models/dealExtraInfo";
import DealStatusContainer from "@/pages/Deals/components/DealStatusContainer";
import {mapGetters} from "vuex";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import BaseTextArea from "@/components/Inputs/BaseTextArea";
import dealWeeklyFrequencies from "@/pages/Deals/resources/dealWeeklyFrequencies";

export default {
  name: "DealSummary",
  components: {
    BaseTextArea,
    ListGroupItemComponent,
    OctoIcon,
    DealStatusContainer,
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch
  },
  data() {
    return {
      newStatus: null,
      newSubStatus: null,
      labelWidth: 300,
      weeklyFrequencyOptions: dealWeeklyFrequencies,
    }
  },
  props: {
    deal: {
      type: Object,
      default: () => this.$_.cloneDeep(Deal)
    },
    showDealDates: {
      type: Boolean,
      default: false
    },
    allowedStatus: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    showExtraFields: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users'
    }),

    dealStatuses() {
      return this.$_.map(this.$_.filter((DealStatuses), (itemToFilter) => {
        return this.allowedStatus.includes(itemToFilter.value);
      }), (itemToMap) => {
        return {
          value: itemToMap.value,
          label: this.$t('deal.status.' + itemToMap.value)
        };
      })
    },

    dealStatusInfo() {
      return this.$_.find(DealStatuses, (status) => {
        return status.value === this.newStatus
      })?.info || null
    },

    dealExtraInfo() {
      return this.$_.isEmpty(this.deal.dealExtraInfo)
        ? DealExtraInfo
        : this.deal.dealExtraInfo
    },

    dealSubStatuses() {
      if (this.$_.isEmpty(this.newStatus)) {
        return [];
      }

      return this.$_.map(DealStatuses[this.newStatus].subStatuses, (subStatus) => {
        return {
          value: subStatus,
          label: this.$t('deal.status.' + subStatus)
        };
      })
    },
  },
  methods: {
    getStatus: function () {
      return this.newStatus;
    },

    getSubStatus() {
      return new Promise((resolve, reject) => {
        if (!!this.newStatus && DealStatuses[this.newStatus].subStatuses.length > 0 && !this.newSubStatus) {
          this.$notify({
            type: 'danger',
            message: this.$t('notifications.cannot_update_deal_without_sub_status')
          });
          reject();
        } else {
          resolve(this.newSubStatus);
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.appointment-icon {
  font-size: 2em;
}
</style>
