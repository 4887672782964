<template>
  <ul class="mx-3">
    <li class="d-flex flex-row align-items-center justify-content-between">
      <div class="reh m1"><pre> </pre></div>
      <div class="reh m2"><pre> </pre></div>
      <div class="reh m3"><pre> </pre></div>
    </li>
  </ul>
</template>

<script>
export default {
name: "AnimatedArrows"
}
</script>

<style scoped lang="scss">
.reh{
  width:10px;
  height:10px;
  position:relative;
  box-shadow: 2px -2px #3498DB;
  transform: rotate(45deg);
  border-radius:1px;
}
.m1 {
  animation: ani3 1.0s steps(5, start) infinite;
  -webkit-animation: ani3 1.0s steps(5, start) infinite;
}
.m2 {
  animation: ani2 1.0s steps(5, start) infinite;
  -webkit-animation: ani2 1.0s steps(5, start) infinite;
}
.m3 {
  animation: ani1 1.0s steps(5, start) infinite;
  -webkit-animation: ani1 1.0s steps(5, start) infinite;
}
@keyframes ani1 {
  0% {opacity:0.1;}
  11% {opacity:0.1;}
  22% {opacity:0.1;}
  33% {opacity:0.15;}
  44% {opacity:0.25;}
  55% {opacity:0.5;}
  66% {opacity:1;}
  77% {opacity:0.5;}
  88% {opacity:0.25;}
  100% {opacity:0.15;}
}
@keyframes ani2 {
  0% {opacity:0.1;}
  11% {opacity:0.15;}
  22% {opacity:0.25;}
  33% {opacity:0.5;}
  44% {opacity:1;}
  55% {opacity:0.5;}
  66% {opacity:0.25;}
  77% {opacity:0.15;}
  88% {opacity:0.1;}
  100% {opacity:0.1;}
}
@keyframes ani3 {
  0% {opacity:0.25;}
  11% {opacity:0.5;}
  22% {opacity:1;}
  33% {opacity:0.5;}
  44% {opacity:0.25;}
  55% {opacity:0.15;}
  66% {opacity:0.1;}
  77% {opacity:0.1;}
  88% {opacity:0.1;}
  100% {opacity:0.15;}
}
</style>
