<template>
    <base-alert
      :style="{backgroundColor: schedulerService.getDealStatusColor(this.localStatus)}"
      class="py-4"
    >
      <div class="text-white text-center text-uppercase" v-if="localStatus">
        {{ $t('deal.status.' + localStatus) }}
      </div>
      <div class="text-center text-uppercase small" v-if="localSubStatus">
        {{ $t('deal.status.' + localSubStatus) }}
      </div>
    </base-alert>
</template>

<script>

import BaseAlert from "@/components/BaseAlert";
import Deal from "@/models/deal";
import {schedulerService} from "@/util/scheduler-service";

export default {
  name: "DealStatusContainer",
  components: {BaseAlert},
  data() {
    return {
      localStatus: null,
      localSubStatus: null,
      schedulerService: schedulerService
    }
  },
  props: {
    deal: {
      type: Object,
      default: () => this.$_.cloneDeep(Deal)
    },

  },
  mounted() {
    this.localStatus = this.$_.clone(this.deal.status);
    this.localSubStatus = this.$_.clone(this.deal.sub_status);
  },
}
</script>

<style scoped>

</style>
