<template>
  <div>
    <div class="container-fluid">
      <div class="row ">
        <div :class="direction === 'row' ? 'col-md-12' : 'col-md-6'" class="d-flex align-items-center my-2">
          <div class="flex-grow-1">
            <base-input class="mb-0">
              <el-select
                :placeholder="$t('callcenter.select_phone')"
                :disabled="inCalling"
                v-model="phoneSelected"
                class="select-default text-uppercase"
              >
                <el-option
                  v-for="(phone, indexPhone) in phones"
                  v-bind:key="`phoneSelect-${indexPhone}`"
                  class="select-default text-uppercase"
                  :value="phone.phone"
                  :label="phone.phone"
                >
                  <span class="float-left">{{ phone.phone }}</span>
                  <label-theme-component class="float-right small text-uppercase ml-2">
                    {{ phone.label }}
                  </label-theme-component>
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div class="ml-auto" v-show="inCalling">
            <animated-arrows/>
          </div>
          <div class="ml-2">
            <base-button
              :disabled="!phoneSelected"
              v-if="!inCalling"
              @click="startCall(phoneSelected)"
              type="primary" icon>
              <octo-icon icon="telephone-1"/>
            </base-button>
            <base-button v-if="inCalling"
                         @click="endCall"
                         type="info" icon>
              <octo-icon icon="telephone"/>
            </base-button>
          </div>
        </div>
        <div :class="direction === 'row' ? 'col-md-12' : 'col-md-6'" class="d-flex align-items-center my-2">
          <div class="flex-grow-1">
            <base-input class="mb-0">
              <el-select
                :placeholder="$t('callcenter.select_email')"
                v-model="emailSelected"
                class="select-default text-uppercase"
              >
                <el-option
                  v-for="(email, indexEmail) in emails"
                  v-bind:key="`emailSelect-${indexEmail}`"
                  class="select-default text-uppercase"
                  :value="email.email"
                  :label="email.email"
                >
                  <span class="float-left text-truncate">{{ email.email }}</span>
                  <label-theme-component class="float-right small text-uppercase text-truncate ml-2">
                    {{ email.label }}
                  </label-theme-component>
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div class="ml-2">
            <base-button :disabled="!emailSelected"
                         @click="startEmail(emailSelected)"
                         type="success" icon>
              <octo-icon icon="email"/>
            </base-button>
          </div>
        </div>
      </div>
    </div>
    <octo-email-modal
      self-managed
      module-name="call-center"
      :show-modal="showEmailModal"
      :email-fields="emailFields"
      :cc="cc"
      :key="emailModalKey"
      @sendEmail="sendEmail"
    />
  </div>
</template>

<script>
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {mapGetters} from "vuex";
import {endpoints} from "@/routes/endpoints";
import OctoEmailModal from "@/components/octo-email-modal/OctoEmailModal";
import AnimatedArrows from "@/pages/CallCenter/OperatorComponents/AnimatedArrows";
import CallCenterCall from "@/models/callCenterCall";
import Lead from "@/models/lead";
import {MessageBox, Option, Select} from "element-ui";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "OperatorActionContainer",
  components: {
    LabelThemeComponent,
    AnimatedArrows,
    OctoIcon,
    OctoEmailModal,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      endpoints: endpoints,
      showEmailModal: false,
      emailModalKey: 1,
      emailFields: {},
      phoneSelected: null,
      emailSelected: null,
      inCalling: false,
      cc: [],
      callCenterCall: this.$_.cloneDeep(CallCenterCall),
    }
  },
  props: {
    direction: {
      type: String,
      default: 'row'
    },
    lead: {
      type: Object,
      default: () => _.cloneDeep(Lead)
    },
    phones: {
      type: Array,
      default: () => []
    },
    emails: {
      type: Array,
      default: () => []
    },
    appointmentId: {
      type: Number|String,
      default: null
    },
    courseIds: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: ''
    },
    surname: {
      type: String,
      default: ''
    },
  },
  watch: {
    phones: {
      handler(val) {
        this.phoneSelected = null
      },
      deep: true
    },
    emails: {
      handler(val) {
        this.emailSelected = null
      },
      deep: true
    }
  },

  methods: {

    startEmail(email) {
      this.showEmailModal = true;

      this.emailFields = {
        email: email,
        name: this.name || '',
        surname: this.surname || '',
        appointment_id: this.appointmentId,
        course_ids: this.courseIds
      }

      this.emailModalKey++;
    },

    sendEmail(emailData) {
      this.$fullLoading.show();
      emailData.id = this.lead.id;
      this.$api.post(endpoints.CALL_CENTER_SEND_EMAIL.replace('{id}', this.lead.id), emailData)
        .then(() => {
          this.showEmailModal = false;
          this.emailModalKey++;
          this.$emit('onEmailSent');
          this.$notify({type: 'success', message: this.$t('notifications.data_sent')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    },

    startCall(phone) {
      this.callCenterCall.number = phone;
      this.inCalling = true;
      this.$api.post(endpoints.CALL_CENTER_CALL_START, {
        number: phone,
        lead_id: this.lead.id
      })
        .then((resp) => {
          this.callCenterCall = resp?.data?.data;
          this.$notify({type: 'success', message: this.$t('notifications.call_success')});
          this.$notify({
            type: 'info',
            message: this.callCenterCall.call_id
              ? this.$t('notifications.phone_error')
              : this.$t('notifications.phone_error')
          });

          this.$emit('onStartCall');
        })
        .catch((e) => {
          this.inCalling = false;
          this.callCenterCall = this.$_.cloneDeep(CallCenterCall);
          this.$notify({type: 'danger', message: this.$t('notifications.call_error')});
          if(e?.response?.data?.message === 'lead_owner_mismatch') {
            MessageBox({
              title: this.$t('notifications.call_error'),
              message: this.$t('notifications.lead_owner_mismatch'),
              confirmButtonText: this.$t('common.ok'),
              type: 'warning'
            })
              .finally(() => {
              });
          }
        })

    },

    endCall() {
      this.inCalling = false;

      this.$api.post(endpoints.CALL_CENTER_CALL_END.replace('{id}', this.callCenterCall.id))
        .then((resp) => {
          this.callCenterCall = resp?.data?.data;
          this.$notify({
            type: 'info',
            message: this.callCenterCall.call_id
              ? this.$t('notifications.phone_closed')
              : this.$t('notifications.phone_error')
          });
        })
        .catch((e) => {
          this.callCenterCall = this.$_.cloneDeep(CallCenterCall);
          this.$notify({type: 'danger', message: this.$t('notifications.call_error')});
        })

      this.callCenterCall = this.$_.cloneDeep(CallCenterCall);
      this.$notify({type: 'success', message: this.$t('notifications.phone_closed')});
    }

  },
  computed: {
    ...mapGetters({}),

    phonesList: function () {
      return this.$_.map(this.phones, (item) => {
        item.progress = item.phone === this.callCenterCall.number;
        return item;
      })
    }
  }
}
</script>

<style scoped>

</style>
